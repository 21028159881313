<template>
    <h1></h1>
    <div>加载中...111</div>
</template>
<script>
import axios from 'axios';
export default {
    mounted() {
        this.jump();
        // this.jume1()
    },
    methods: {
        jump() {
            var _this = this;
            var currentURL = window.location.href;
            var extractedString;
            const match = currentURL.match(/code=(.*?)&state=/);
            if (match) {
                extractedString = match[1];
            }
            const url = 'https://s.unisignflex.com/unisign/wechat/getuserbycode'; // 替换为你的POST接口URL
            const data = {
                code: extractedString,
            };
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Basic OGJyNnF3dWNncGE1dnp5c2N5OThieWFxdzN5c212ZDY6cGFocWVqdjNjYnU1bXAydTI4enI3eDNhbWd2ZzV5NXA=');
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var response = JSON.parse(xhr.responseText);
                        const userInfo = {
                            systemname: "核价系统",
                            username: response.data.name,
                            userposition: response.data.position,
                        };
                        const expirationDate = new Date();
                        expirationDate.setDate(expirationDate.getDate() + 1);
                        document.cookie = `userInfo=${JSON.stringify(userInfo)}; expires=${expirationDate.toUTCString()}; path=/`;
                        // 写入登录用户信息到明道
                        // _this.WriteUser(userInfo);
                        _this.$router.push({
                            path: '/pricing',
                            query: { username: response.data.name, id: Math.random() * (100 - 1) + 1 }
                        });
                    } else {
                        console.error('POST请求失败');
                    }
                }
            };
            xhr.send(JSON.stringify(data));
        },
        WriteUser(ele) {
            var _this = this;
            //获取当前日期
            const currentDate = new Date();
            const data = {
                appKey: 'de090149861618aa',
                sign: 'OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==',
                worksheetId: "64ddf1631777cd57b6e3e022",
                controls: [{
                    "controlId": "mingcheng",
                    "value": ele.systemname,
                    "valueType": 2,
                },
                {
                    "controlId": "dqyhzt",
                    "value": "正常",
                    "valueType": 2,
                },
                {
                    "controlId": "dqdlr",
                    "value": ele.username,
                },
                {
                    "controlId": "usergw",
                    "value": ele.userposition,
                },
                {
                    "controlId": "zjycdlsj",
                    "value": currentDate,
                }
                ]
            };
            axios.post('https://api.mingdao.com/v2/open/worksheet/addRow', data)
                .then(response => {
                    // 处理接口响应
                    this.response = response.data;


                })
                .catch(error => {
                    console.error('POST请求出错：', error);
                });
        },
    },
};
</script>