<template>
    <div>测试页面</div>
</template>

<script>
export default {
    mounted() {
        this.jump();
    },
    methods: {
        jump() {
            console.log(454);
            // const userInfo = {
            //     systemname: "核价系统",
            //     username: 55,
            //     userposition: Math.random() * (100 - 1) + 1,
            // };
            // const expirationDate = new Date();
            // expirationDate.setDate(expirationDate.getDate() + 1);
            // document.cookie = `userInfo=${JSON.stringify(userInfo)}; expires=${expirationDate.toUTCString()}; path=/`;
            // // 写入登录用户信息到明道
            // // this.WriteUser(userInfo);
            // this.$router.push({
            //     path: '/',
            //     query: { username: userInfo.userposition }
            // });
        }
    }
}
</script>