import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.css'; // 引入Bootstrap的CSS
import 'bootstrap/dist/js/bootstrap.js';   // 引入Bootstrap的JavaScript文件
import axios from 'axios';


createApp(App).use(store).use(router).mount("#app");
