import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import home from "../views/home.vue";
import login from "../views/login.vue";
import pricing from "../views/pricing.vue";
import tes from "../views/tes.vue";
import axios from 'axios';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/pricing',
    },
    {
      path: '/login',
      name: 'login',
      component: login,
    },
    {
      path: '/tes',
      name: 'tes',
      component: tes,
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: pricing,

    },
  ],
});

router.beforeEach((to, from, next) => {
  // let userInfo = decodeURIComponent(document.cookie);
  // let userInfoIndex = userInfo.indexOf("=");
  // var result;
  // if (userInfoIndex !== -1) {
  //   result = JSON.parse(userInfo.substring(userInfoIndex + 1));
  // } else {
  //   console.log("未找到指定字符");
  // }
  // console.log(userInfo);

  if (to.path == '/login') {
    next()
  }
  else {
    if (to.path == '/' || to.path == '/pricing') {
      next();
      // if (!userInfo) {
      //   next()
      //   // window.location.href = 'https://login.work.weixin.qq.com/wwlogin/sso/login/?login_type=CorpApp&appid=ww2ce6ce8502388dc4&agentid=1000026&redirect_uri=https%3A%2F%2Fs.unisignflex.com%2F%23%2Flogin&state=WWLogin';
      // } else {
      //   next()
      // }
    } else {
      next()
    }
  }

})

export default router;
