<template>
    <body class="unisign">
        <div class="herd">
            <div class="logo">
                <img src="../assets/logo.png" alt=""><span>在线核价系统</span>
            </div>
            <div class="userBox">
                用户名：<span>{{ $route.query.userInfo }}</span>
            </div>
        </div>
        <div class="container">
            <div class="top">
                <div class="form-group form-box WriteData">
                    <div class="form-group searchInputBox cpmcBox">
                        <label>产品名称:</label>
                        <div class="inputBox">
                            <input type="text" :readonly="isReadOnly" @focus="handleFocus"
                                class="form-control searchInput toggleReadOnly" placeholder="点这里搜索">
                            <div class="luchen_box"></div>
                        </div>
                        <ul class="selectOptions cpmcList">
                        </ul>
                    </div>
                    <div class="form-group mrkz">
                        <label>克重:</label>
                        <input type="text" class="form-control Ucpkz input0" placeholder="">
                    </div>
                    <div class="pricingBox">
                        <a>核价</a>
                        <span>出厂含税价：<b></b></span>
                    </div>
                </div>
                <div class="form-group form-box WriteData">
                    <div class="form-group searchInputBox mrjglx">
                        <label>加工类型:</label>
                        <div class="inputBox">
                            <input type="text" :readonly="isReadOnly" @focus="handleFocus"
                                class="form-control searchInput toggleReadOnly" placeholder="点这里搜索">
                            <div class="luchen_box"></div>
                        </div>
                        <ul class="selectOptions">
                        </ul>
                    </div>
                    <div class="form-group mrjgf">
                        <label>加工费:</label>
                        <input type="text" class="form-control Ujgf" placeholder="">
                    </div>
                    <div class="form-group mrshl">
                        <label>损耗率:</label>
                        <input type="text" class="form-control Ushl" placeholder="">
                    </div>
                    <div class="form-group sfVysj">
                        <label>是否需要上浆:</label>
                        <div>
                            <div>是<input type="checkbox" appearance="none" class="shi" placeholder=""></div>
                            <div>否<input type="checkbox" class="fou" placeholder=""></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="middle">
                <form action="">
                    <table>
                        <tbody>
                            <tr class="tr-header">
                                <th>分类</th>
                                <th>材料名称</th>
                                <th>价格/单位</th>
                                <th>克重</th>
                                <th>材料描述</th>
                                <th>更新时间</th>
                                <th>操作</th>
                            </tr>
                            <tr class="tr-box wbgg-tr WriteData">
                                <th>网布规格</th>
                                <td class="searchInputBox clmc mrwbgg">
                                    <div class="inputBox">
                                        <input :readonly="isReadOnly" @focus="handleFocus"
                                            class="form-control searchInput toggleReadOnly" type="text" placeholder="">
                                        <div class="luchen_box"></div>
                                    </div>
                                    <ul class="selectOptions">
                                    </ul>
                                </td>
                                <td class="jgdw"><input class="form-control Uwbjg" type="text"
                                        placeholder="价格"><span></span></td>
                                <td class="kz"><input class="form-control Uwbkz" type="text" placeholder="克重">
                                </td>
                                <td class="clms"><span></span></td>
                                <td class="gxrq"><span></span></td>
                                <td class="cz qingkong"><a>清空</a></td>
                            </tr>
                            <tr class="tr-box pvcm-tr WriteData">
                                <th>PVC膜</th>
                                <td class="searchInputBox clmc mrPVCm">
                                    <div class="inputBox">
                                        <input :readonly="isReadOnly" @focus="handleFocus"
                                            class="form-control searchInput toggleReadOnly" type="text" placeholder="">
                                        <div class="luchen_box"></div>
                                    </div>
                                    <ul class="selectOptions">
                                    </ul>
                                </td>
                                <td class="jgdw"><input class="form-control Upvcmjg" type="text"
                                        placeholder="价格"><span></span></td>
                                <td class="kz"></td>
                                <td class="clms"><span></span></td>
                                <td class="gxrq"><span></span></td>
                                <td class="cz qingkong"><a>清空</a></td>
                            </tr>
                            <tr class="tr-box pvcfjtx-tr">
                                <th>添加特性</th>
                                <td class="addFjtx"><span>+ 添加</span></td>
                            </tr>
                            <tr class="tr-box jllx-tr WriteData">
                                <th>浆料类型</th>
                                <td class="searchInputBox clmc mrjllx">
                                    <div class="inputBox">
                                        <input :readonly="isReadOnly" @focus="handleFocus"
                                            class="form-control searchInput toggleReadOnly" type="text" placeholder="">
                                        <div class="luchen_box"></div>
                                    </div>
                                    <ul class="selectOptions">
                                    </ul>
                                </td>
                                <td class="jgdw"><input class="form-control Ujljg" type="text" placeholder="价格"><span
                                        class="UjljgS"></span></td>
                                <td class="kz"><input class="form-control Ujlkz" type="text" placeholder="克重">
                                </td>
                                <td class="clms"><span></span></td>
                                <td class="gxrq"><span></span></td>
                                <td class="cz qingkong"><a></a></td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            <div class="bottom">
                <form>
                    <table>
                        <tr class="tr-header">
                            <th>产品克重</th>
                            <th>膜价</th>
                            <th>网布价格/克重</th>
                            <th>加工费</th>
                            <th>损耗率</th>
                            <th class="ac_jllx active">浆料价格/克重</th>
                        </tr>
                        <tr class="tr-td">
                            <td><span class="Ucpkz"></span></td>
                            <td><span class="Upvcmjg"></span></td>
                            <td><span class="Uwbjg"></span><span class="Uwbkz"></span></td>
                            <td><span class="Ujgf"></span></td>
                            <td><span class="Ushl"></span></td>
                            <td class="ac_jllx active"><span class="Ujljg"></span><span class="Ujlkz"></span></td>
                        </tr>
                    </table>
                </form>
                <div class="pricingBox pricingBox0">
                    <a>核价</a>
                    <span>出厂含税价：<b></b></span>
                </div>
            </div>
        </div>
        <div class="abc"></div>

    </body>
</template>

<style>
* {
    padding: 0;
    bottom: 0;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

input.form-control {
    border-radius: 0.25rem;
    font-size: 14px;
    color: #000;
}

.unisign {
    padding: 50px;
    background: #f1f1f1;
}

.container {
    background: #fff;
    padding: 20px 0;
    border-radius: 5px;
}

/* top */
.unisign .form-box {
    display: flex;
    margin: 0;
}

.unisign .form-box>div {
    width: 20%;
    position: relative;
    margin: 0 15px 30px;
    font-size: 14px;
}

.unisign .form-box>div.searchInputBox {
    min-width: 300px;
}

.inputBox {
    position: relative;
}

.inputBox input {
    position: relative;
    z-index: 1;
}

.inputBox .luchen_box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 11;
    cursor: pointer;
    display: block;
}

.inputBox .luchen_box.active {
    display: none;
}

.selectOptions {
    list-style: none;
    height: 0;
    overflow: auto;
    transition: all .3s;
    background: #f2f2f2;
    margin: 0;
    position: absolute;
    z-index: 1000;
    width: 130%;
    top: 70px;
    box-shadow: 1px 1px 2px #ddd;
    padding: 0;
}

.selectOptions li {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    cursor: pointer;
    line-height: 1.4;
}



.selectOptions li span {
    margin: 0 0 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font: 13px;
    opacity: .8;

}

.selectOptions li span.cplxSpan {
    width: 0;
    margin: 0;
    overflow: hidden;
}

.selectOptions li:not(.input):hover {
    background: #318ae4;
    color: #fff;
}

.searchInput {
    cursor: pointer;
    z-index: 299;
    position: relative;
}

.selectOptions.active {
    height: 200px;
}

.form-control {
    padding: 5px 30px 5px 10px;
    height: auto;
    line-height: 1;
    font-size: 14px;
}

/* middle */
.unisign .middle,
.unisign .bottom {
    margin: 15px;
    padding: 0 0 15px;
}

form table {
    width: auto;
}



form th,
form td,
form tr {
    border: 1px solid #ddd;
    padding: 12px 10px;
    font-size: 14px;
    color: #0b1019;
    position: relative;
}

.middle tr {
    height: 58px;
}

form .tr-box th {
    width: 80px;
    min-width: 85px;
}

.jgdw {
    width: 157px;
    min-width: 157px;
}

.jgdw span {
    min-width: 36px;
    font-size: 12px;
    margin: 0 0 0 5px;
}

.jgdw input {
    width: 70px;
}

form td.kz {
    width: 55px;
    min-width: 60px;
}

form input.form-control {
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px;
    height: 33px;
    display: inline-block;
}

form input.form-control.searchInput {
    padding: 5px 30px 5px 5px;
}

form td.clmc {
    width: 210px;
    min-width: 210px;
}

form td a {
    border: none;
    color: #ff0000 !important;
    background: none;
    padding: 2px 5px;
    border-radius: 3px;
    cursor: pointer;
}

form td a:hover {
    border-color: transparent;
    background-color: rgba(0, 91, 245, .06);
}

input[type=text]:focus {
    border-color: #005BF5 !important;
    box-shadow: none !important;
}



a:focus {
    border-color: red !important;
    box-shadow: none !important;
}

td .selectOptions {
    top: 50px;
    width: 235px;
}

td.clms {
    min-width: 200px;
}

form td {
    width: auto;
    min-width: 60px;
}

form td.gxrq {
    min-width: 100px;
    font-size: 12px;
    padding: 12px 5px;
    text-align: center;
}

form .clmc input {
    text-align: left;
}

.sfVysj div {
    display: flex;
}

.sfVysj>div {
    margin: 5px 0 0;
    pointer-events: none;
}

.sfVysj>div>div {
    margin: 0 25px 0 0;
}

.sfVysj>div>div input {
    margin: 0 0 0 5px;
    outline: none;
}

.jllx-tr {
    display: table-row;
}

.jllx-tr.active2 {
    display: none;
}

/* bottom */
.unisign .bottom {
    min-width: 750px;
}

.bottom th {
    background: #fcfcfc;
    padding: 5px 10px;
}

.bottom td {
    line-height: 1;
    text-align: center;
    padding: 0;
}

.bottom td span {
    width: 50%;
    height: 100%;
    line-height: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
    position: relative;
    top: 1px;
}

.bottom td span:nth-child(2) {
    border-left: 1px solid #ddd;
}

.pricingBox {
    display: flex;
    margin: 0 15px;
}

.pricingBox a {
    background: #eee;
    border: none;
    display: flex;
    color: #aaa;
    width: 85px;
    height: 40px;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.pricingBox.active a {
    background: #5ac800;
    color: #fff;
    pointer-events: all;
}

.pricingBox span {
    display: flex;
    margin: 20px 0 0 20px;
    font-size: 13px;
    min-width: 80px;
}

.pricingBox span b {
    color: #ff0000;
    font-size: 18px;
    margin: -4px 0 0 0;
}

.pricingBox a {
    outline: none;
}

.addFjtx {
    border-right: none;
    cursor: pointer;
    color: #005BF5;
}

.addFjtx span {
    padding: 2px 5px;
    border-radius: 3px;
}

.addFjtx:hover span {
    color: #005BF5;
    border-color: transparent;
    background-color: rgba(0, 91, 245, .06);
}

.tr-box.active1 {
    display: none;
}

.pricingBox.avtive a {
    background: #5ac800;
    color: #fff !important;
    cursor: pointer;
    pointer-events: auto;
}

.pricingBox.avtive:hover a {
    color: #fff;
    background: #1c9e20;
}

.unisign .form-box>div.pricingBox {
    width: 44%;
    display: flex;
    align-items: end;
}

.luchen_box {
    position: relative;
}

.luchen_box::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #ddd;
    margin-top: 20px;
    position: absolute;
    right: 8px;
    top: -7px;
    z-index: 999;
    cursor: pointer;
}

.WriteData .searchInputBox.active_sousuo::after {
    content: "";
    width: 18px;
    height: 18px;
    background: #fff url("../assets/icon-sousuo.png") no-repeat;
    background-size: contain;
    position: absolute;
    top: 35px;
    right: 5px;
    z-index: 999;
}

.middle .WriteData .searchInputBox.active_sousuo::after {
    top: 19px;
    right: 15px;
}

.WriteData .searchInputBox:hover::before {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #318ae4;
}

.middle .WriteData .searchInputBox::before {
    top: 7px;
    right: 17px;
}

.active11 {
    display: none !important;
}

.ac_jllx.active {
    display: none;
}

.unisign .herd {
    display: flex;
    max-width: 960px;
    margin: 0 auto 20px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.logo {
    display: flex;
}

.logo img {
    max-width: 180px;
}

.logo span,
.userBox {
    display: flex;
    align-items: end;
    margin: 0 0 0 15px;
    font-size: 15px;
}

.userBox {
    font-size: 13px;
}

.middle.active9,
.mrjgf.active9,
.mrshl.active9,
.sfVysj.active9 {
    pointer-events: none;
}

.middle.active9 input,
.mrjgf.active9 input,
.mrshl.active9 input,
.sfVysj.active9 input {
    background: rgba(200, 200, 200, .1);

}

.mrjglx.active9 input,
.mrwbgg.active9 input,
.mrPVCm.active9 input,
.mrkz.active9 input {
    border-color: rgb(255, 0, 0);
}

.pricingBox0 a {
    display: none;
}

.unisign .bottom {
    display: flex;
}

.pricingBox0 span {
    margin: 65px 0 0 15px;
}

@media (max-width: 1000px) {
    .selectOptions {
        width: 200%;
    }

    .herd {
        display: flex;
        max-width: 960px;
        margin: 30px auto 20px;
    }

    .unisign {
        padding: 10px;
    }

    .unisign .form-box>div.searchInputBox {
        min-width: 160px;
    }

    .container {
        max-width: 100%;
    }

    td.clms {
        min-width: 120px;
    }

    .jgdw {
        min-width: 130px;
    }

    .jgdw input {
        min-width: 60px;
    }

    .jgdw span {
        font-size: 12px;
        margin: 0 0 0 5px;
        letter-spacing: -1px;
        position: absolute;
        top: 50%;
        margin: -11px 3px 0;
    }

    form th,
    form td,
    form tr {
        padding: 10px 5px;
        font-size: 12px;
    }

    form td.clmc {
        min-width: 165px;
    }

    form td {
        min-width: 48px;
    }

    .unisign .bottom {
        min-width: 600px;
    }

    form input.form-control {
        font-size: 12px;
    }
}

@media (min-width: 768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 960px !important;
    }


}

@media (min-width: 576px) {

    .container,
    .container-sm {
        max-width: 960px !important;
    }
}

@media (max-width: 750px) {
    .herd {
        display: flex;
        max-width: 540px;
        margin: 30px auto 20px;
    }

    .unisign .bottom {
        min-width: auto;
        overflow-x: scroll;
    }

    form {
        overflow-x: scroll;
        padding: 0 0 40px;
    }

    td.mrjllx .selectOptions.active {
        top: -94px;
        height: 100px;
    }

    .selectOptions.active {
        height: 292px;
    }

    .selectOptions {
        width: 135%;
    }

    .pvcm-tr .selectOptions.active {
        height: 110px;
    }

    .wbgg-tr .selectOptions.active {
        height: 120px;
    }

    .unisign .form-box {
        flex-wrap: wrap;
    }

    .unisign .form-box>div {
        width: 40%;
    }

    .unisign .form-box>div.mrkz {
        width: 30%;
    }

    .unisign .form-box>div.pricingBox {
        width: 80%;
    }
}

@media (max-width: 540px) {
    .top .selectOptions {
        width: 190%;
    }

    .container {
        padding: 20px 0 0;
    }

    .logo img {
        max-width: 130px;
    }

    .unisign .herd {
        margin: 0 auto 10px;
    }

    .logo span,
    .userBox {
        font-size: 12px;
        margin: 0;
    }

    .unisign .bottom {
        display: flex;
        flex-direction: column-reverse;
        margin: 0;
    }

    .pricingBox0 span {
        margin: 0;
    }

    .pricingBox0 {
        margin: 0 0 10px;
    }
}
</style>

<script>
import $ from 'jquery';
export default {
    data() {
        return {
            isReadOnly: false, // 初始状态为非只读
            cpId: "",
            cpCs: 0,
            jglxId: "",
            jglxCs: 0,
            wbId: "",
            wbCs: 0,
            mId: "",
            mCs: 0,
            fjtxId: "",
            fjtxCs: 0,
        };
    },
    mounted() {

        // document.cookie = `userInfo={"username":"冯建发"};path=/`;
        // userFun();
        function userFun() {
            let userInfo = decodeURIComponent(document.cookie);
            let userInfoIndex = userInfo.indexOf("=");
            var result;
            if (userInfoIndex !== -1) {
                result = JSON.parse(userInfo.substring(userInfoIndex + 1));
            } else {
                console.log("未找到指定字符");
            }
            // console.log(result);
            $(".userBox span").html(result.username)

        }
        var togglgNum = 0;
        var _this0 = this;

        toggleReadOnly();
        function toggleReadOnly() {
            //点击下拉input框首次不唤醒键盘-事件委托获取js生成的元素
        }
        $(document).ready(function () {
            var wangbuleixing = "";
            // 点击目标区域之外的区域，隐藏目标区域
            $(document).click(function (event) {
                if ($(event.target).closest('.luchen_box').length > 0) {
                    $(event.target).addClass("active")
                    // 显示下拉框
                    $(".searchInputBox.active").removeClass("active")
                    event.target.parentNode.parentNode.classList.add("active")
                    $(".searchInputBox.active .selectOptions").addClass("active")
                    event.target.parentNode.parentNode.classList.add("active_sousuo");
                } else {
                    // console.log(!$('.searchInput').is($(event.target)));
                    // 隐藏下拉框
                    if (!$('.searchInput').is($(event.target)) && $('.searchInput').has($(event.target)).length === 0) {
                        $(".selectOptions").removeClass("active");
                        $(".searchInputBox").removeClass("active_sousuo")
                        $(".luchen_box").removeClass("active")
                    }

                }


            })

            // 监听输入框的输入事件
            $('.WriteData input.searchInput').on('input', function (event) {
                var _this = this;
                var searchText = $(_this).val().toLowerCase();
                $('.WriteData').each(function (ind, item) {
                    if ($('.WriteData input.searchInput').index(_this) == ind) {
                        $(item).find(".selectOptions li:not(.input)").each(function () {
                            var optionText = $(this).text().toLowerCase();
                            var optionValue = $(this).val();
                            if (optionText.includes(searchText)) {
                                $(this).show();
                            } else {
                                $(this).hide();
                            }
                        });
                    }
                })

            });


            //添加附加特性
            var _arry = [];
            var index0 = 1;
            $(".pvcfjtx-tr .addFjtx").on("click", function (event) {
                var _this;
                if ($(".pvcfjtx-tr0").length == 0) {
                    addFjtxFun(index0);
                } else {
                    var allItemsSatisfyCondition = true;
                    $(".pvcfjtx-tr0").each(function (ind, item) {
                        if ($(this).find(".searchInput").val() == "") {
                            _this = this;
                            $(this).find(".searchInput").css({ 'border-color': '#ff0000' });
                            allItemsSatisfyCondition = false;
                            return false;
                        } else {
                            allItemsSatisfyCondition = true;
                        }
                    })
                    if (allItemsSatisfyCondition) {
                        var _index = $(this).closest(".pvcfjtx-tr0.active").index() - 2;
                        addFjtxFun(_index + 1);
                    }
                }
                $(document).click(function (event) {
                    var target = event.target;
                    if (!$(target).closest('.addFjtx').length) {
                        $(_this).find(".searchInput").css({ 'border-color': '#ddd' });
                    }
                });
                WriteDataFun();

            })

            //生成附加特性
            function addFjtxFun() {
                $(".pvcfjtx-tr").before($('<tr class="tr-box pvcfjtx-tr0 WriteData"><th>附加特性</th><td class="searchInputBox clmc pvcfjtx"><div class="inputBox"><input :readonly="isReadOnly" @focus="handleFocus" class="form-control searchInput toggleReadOnly" type="text" placeholder=""><div class="luchen_box"></div></div><ul class="selectOptions"></ul></td><td class="jgdw"><input class="form-control fjtx" v-model="message"  type="text" placeholder="价格"><span></span></td><td></td><td class="clms"><span></span></td><td class="gxrq"><span></span></td><td class="cz shanchu"><a>删除</a></td></tr>'))
                // 获取附加特性
                var productList6Data0 = JSON.parse(localStorage.getItem('cachedData'));
                $(".middle .pvcfjtx .selectOptions li").remove();//清空数据
                $.each(productList6Data0, function (ind, item) {
                    $(".middle .pvcfjtx .selectOptions").append($('<li>' + item.clmc + '</li >'));
                    // 选择选项写入input
                    $('.pvcfjtx .selectOptions li').on('click', function (event) {
                        var _this = this;
                        var a = false;
                        $(".pvcfjtx-tr0 .searchInput").each(function () {
                            if ($(this).val() == $(_this).text()) {
                                a = true;
                                return false;
                            }
                        })
                        if (a == false) {
                            $(_this).closest(".pvcfjtx-tr0").addClass("active");
                            $(".active.pvcfjtx-tr0").each(function (ind1, item1) {
                                if ($(_this).parent().parent().parent().index() - 3 == ind1) {
                                    if (htmlEscape(_this.innerHTML) == item.clmc) {
                                        _this0.fjtxCs = item.paxvId;
                                        _this0.fjtxId = item.rowid;
                                    }
                                    $(item1).find(".searchInput").val(htmlEscape(_this.innerHTML));
                                    if (htmlEscape(_this.innerHTML) == item.clmc) {
                                        $(item1).find(".jgdw input").val(item.jgdw)//价格
                                        $(item1).find(".jgdw span").text(item.danwei)//默认单位
                                        $(item1).find(".clms span").text(item.clms)//材料描述
                                        $(item1).find(".gxrq span").text(item.updatedate)//更新日期

                                        $.each($(".middle").find(".pvcfjtx-tr0"), function (ind, item) {
                                            var _index = $(_this).closest(".pvcfjtx-tr0.active").index() - 2;
                                            var fjtxVal = $(item).find(".jgdw input").val();
                                            if ($(this).hasClass("active") == true) {
                                                if ($(item).find(".jgdw span").text() == "元 / 吨") {
                                                    fjtxVal = parseFloat(fjtxVal);
                                                } else {
                                                    fjtxVal = fjtxVal + "每平方"
                                                }
                                                if (_arry.indexOf(_index) == -1) {
                                                    _arry.push($(_this).closest(".pvcfjtx-tr0.active").index() - 2);
                                                    $(".bottom .tr-header").append($('<th class="fjtx">附加特性</th>'))//添加附加属性th
                                                    $(".bottom .tr-td").append($('<td class="fjttt fjtx"><span class="fjtx">' + fjtxVal + '</span></td>'))//添加附加属性td
                                                    // $(".bottom .tr-td").append($('<td class="fjttt fjtx"><span class="fjtx">' + { message } + '</span></td>'))//添加附加属性td
                                                } else {
                                                    fjtxVal = parseFloat($(this).find(".fjtx").val());
                                                    if ($(item).find(".jgdw span").text() == "元 / 吨") {
                                                        fjtxVal = parseFloat($(this).find(".fjtx").val());
                                                    } else {
                                                        fjtxVal = $(this).find(".fjtx").val() + "每平方"
                                                    }
                                                    $(".bottom .tr-td").find("span.fjtx").each(function (ind0, item0) {
                                                        if (ind0 == _index - 1) {
                                                            $(item0).text(fjtxVal);
                                                        }

                                                    })
                                                }
                                            }

                                        })
                                    }
                                }
                            })

                        }

                        // return false;

                        //可以核价
                        if ($(".cpmcBox input").val() != "") {
                            $(".pricingBox").addClass("avtive");
                        }

                        originalValue();
                    })


                })
                //同步底部统计表中的附加特性价格
                $(".pvcfjtx-tr0 .jgdw input").each(function (ind, item) {
                    $(".pvcfjtx-tr0 .jgdw input").on("input", function (e) {
                        var _this = this;
                        $(".bottom span.fjtx").each(function (ind2, item2) {
                            if ($(".pvcfjtx-tr0 .jgdw input").index($(_this)) == ind2) {
                                $(item2).text(parseFloat($(_this).val()));

                            }
                        })
                    });
                })

            }


            // 监听输入框写入数据计算成本
            WriteDataFun();
            function WriteDataFun() {
                $('.WriteData input.searchInput').on('keyup', function (event) {
                    var _this = this;
                    $(".bottom .tr-td td span").each(function (ind, item) {
                        if ($(_this).hasClass(item.className) == true) {
                            $(item).text($(_this).val())
                        } else {

                        }

                    })

                });
            }
            // 监听默认值计算成本
            function ListeningDefaults(val, res) {
                $(".bottom .tr-td td span").each(function (ind, item) {
                    if (res.indexOf(item.className) == -1) {

                    } else {
                        $(item).text(val)
                    }
                })
            }

            // 清空数据
            qingkongFun();
            function qingkongFun() {
                $(".qingkong").on("click", function () {
                    $(this).parent().addClass("active0")
                    $(".tr-box.active0 input").val("");
                    $(".tr-box.active0 td span").text("");
                })
            }


            // 删除附件特性
            $(".middle tbody").on("click", ".tr-box .shanchu", function () {
                delFjtxFun(this);
                $(".pricingBox").addClass("avtive");

            })
            function delFjtxFun(res) {
                var _index = $(res).closest(".pvcfjtx-tr0").index() - 2;
                $(".bottom .tr-header").find("th.fjtx").each(function (ind, item) {
                    if (_index == ind + 1) {
                        $(item).remove();//删除附加属性th
                    }
                })
                $(".bottom .tr-td").find("td.fjtx").each(function (ind, item) {
                    if (_index == ind + 1) {
                        $(item).remove();//删除附加属性td
                    }
                })

                if (_arry.indexOf(_index) !== -1) {
                    _arry.splice(_arry.indexOf(_index), 1)
                }
                $(res).parent().remove();
            }


            var productList6Data = [];
            var hjcs = false;
            productList1(1);//加载产品列表
            productList2(1);//加载加工类型列表
            productList3(1);//加载网布规格列表
            productList4(1);//加载PVC膜列表
            productList5(1);//加载浆料类型列表
            productList6(1);//加载附加特性列表
            // 产品列表API
            function productList1(index) {
                // Define the URLs of the APIs
                var apiUrl1 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRows'; //产品列表API
                var apiUrl2 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRowsTotalNum';//产品列表总行数
                var pageSize = 50;
                var pageIndex = index;
                // Function to make a POST request to an API
                function callApi(url, data) {
                    return $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json',
                        data: JSON.stringify(data),
                        contentType: 'application/json'
                    });
                }
                // Data for each API call
                var postData1 = {
                    appKey: "de090149861618aa",
                    sign: "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                    worksheetId: "64afa43449015c458aa1d45b",
                    pageSize: pageSize,
                    pageIndex: pageIndex,
                    sortId: "hjci"

                };
                $.when(
                    callApi(apiUrl1, postData1)
                ).then(function (response1) {
                    var data1 = response1.data.rows;
                    $.each(data1, function (ind, item) {
                        if (item.cpbm != null) {
                            if (item.cpmc == "自选核算") {
                                $(".selectOptions.cpmcList").append($('<li><div>' + item.cpmc + '</div></li >'));
                            } else {
                                if (JSON.parse(item.mrwbgg)[0] != undefined) {
                                    $(".selectOptions.cpmcList").append($('<li><div>' + item.cpmc + '</div><span>' + JSON.parse(item.mrwbgg)[0].name + '</span><span class="cplxSpan">' + item.cplx + '</span></li >'));
                                }
                            }
                        } else {
                            // console.log(item);
                        }

                        // 默认定制产品
                        if (item.cpmc == "自选核算") {
                            $(".cpmcBox .searchInput").val(item.cpmc)
                        }
                        // 选择选项写入input
                        $('.selectOptions.cpmcList li').on('click', function () {
                            //清除禁止操作指令
                            $(".middle").removeClass("active9");
                            $(".mrjgf").removeClass("active9");
                            $(".mrshl").removeClass("active9");
                            $(".mrjglx").removeClass("active9");
                            $(".shanchu").each(function () {
                                delFjtxFun(this)//清空附件特性
                            })
                            $(".active .searchInput").val($(this).find("div")[0].innerHTML);
                            if ($(this).find("div")[0].innerHTML == item.cpmc) {
                                $(".unisign input:not(.cpmcBox input)").val("") // 清空所有
                                $(".form-group.mrkz input").val(item.kezhong);
                                ListeningDefaults(item.kezhong, $(".form-group.mrkz input").attr("class"))
                                //加工类型
                                if (JSON.parse(item.mrjggy_lx_)[0] != undefined) {
                                    jglxDetails(JSON.parse(item.mrjggy_lx_)[0].sid)
                                }
                                // 网布规格
                                if (JSON.parse(item.mrwbgg)[0] != undefined) {
                                    $.each(JSON.parse(item.mrwbgg), function (ind, item) {
                                        wbggDetails(item.sid)
                                    })
                                }
                                // PVC膜
                                if (JSON.parse(item.mrpvcm)[0] != undefined) {
                                    $.each(JSON.parse(item.mrpvcm), function (ind, item) {
                                        mrpvcmDetails(item.sid)
                                    })
                                }
                                setTimeout(function () {
                                    costingAccount()//开始核价
                                }, 1000);
                                if (item.cpmc != "自选核算") {
                                    PricingFrequency(parseInt(item.hjci), item.rowid, "产品名称")//核价次数
                                    PricingFrequency(parseInt(item.wbggHjpc), JSON.parse(item.mrwbgg)[0].sid, "网布规格")//核价次数
                                    PricingFrequency(parseInt(item.jglxHjpc), JSON.parse(item.mrjggy_lx_)[0].sid, "加工工艺(类型)")//核价次数
                                    PricingFrequency(parseInt(item.mHjpc), JSON.parse(item.mrpvcm)[0].sid, "PVC膜")//核价次数
                                }

                            }
                            originalValue();

                        })
                    })
                    var total = response1.data.total;
                    var maxPage = Math.round(total / pageSize) + 1;
                    if (pageIndex < maxPage) {
                        productList1(pageIndex + 1);
                    }
                }).fail(function (error) {
                    console.error('Error:', error);
                });
            }
            // 核价次数
            function PricingFrequency(res, rowId, name) {
                res += 1;
                var appKey, sign, worksheetId, controls = [];
                if (name == "产品名称") {
                    appKey = "de090149861618aa";
                    sign = "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==";
                    worksheetId = "64afa43449015c458aa1d45b";
                    controls = [
                        {
                            "controlId": "hjci",
                            "value": res
                        }
                    ]
                }
                if (name == "网布规格") {
                    appKey = "de090149861618aa";
                    sign = "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==";
                    worksheetId = "64bb7a8d29545d478c720c24";
                    controls = [
                        {
                            "controlId": "hjpc",
                            "value": res
                        }
                    ]
                }
                if (name == "加工工艺(类型)") {
                    appKey = "de090149861618aa";
                    sign = "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==";
                    worksheetId = "64afa8ee6d548a8f59c4c73a";
                    controls = [
                        {
                            "controlId": "sortId",
                            "value": res
                        }
                    ]
                }
                if (name == "PVC膜") {
                    appKey = "de090149861618aa";
                    sign = "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==";
                    worksheetId = "64b3e62a346aa010db8013a0";
                    controls = [
                        {
                            "controlId": "pxqz",
                            "value": res
                        }
                    ]
                }
                if (name == "附加特性") {
                    appKey = "de090149861618aa";
                    sign = "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==";
                    worksheetId = "64c0d4ecbb5a76c253907792";
                    controls = [
                        {
                            "controlId": "paxvId",
                            "value": res
                        }
                    ]
                }
                // 数据准备
                var requestData = {
                    "appKey": appKey,
                    "sign": sign,
                    "worksheetId": worksheetId,
                    "rowId": rowId,
                    "controls": controls
                };
                // 发起POST请求
                $.ajax({
                    url: "https://api.mingdao.com/v2/open/worksheet/editRow",
                    type: "POST",
                    data: JSON.stringify(requestData),
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    success: function (response) {
                    },
                    error: function (xhr, status, error) {
                        // 处理错误
                        $("#response").text("错误：" + error);
                    }
                });
            }
            //加工类型列表API
            function productList2(index) {
                // Define the URLs of the APIs
                var apiUrl1 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRows'; //产品列表API
                var apiUrl2 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRowsTotalNum';//产品列表总行数
                var pageSize = 50;
                var pageIndex = index;

                // Function to make a POST request to an API
                function callApi(url, data) {
                    return $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json',
                        data: JSON.stringify(data),
                        contentType: 'application/json'
                    });
                }
                // Data for each API call
                var postData1 = {
                    appKey: "de090149861618aa",
                    sign: "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                    worksheetId: "64afa8ee6d548a8f59c4c73a",
                    pageSize: pageSize,
                    pageIndex: pageIndex,
                    sortId: "sortId",
                    isAsc: false
                };
                $.when(
                    callApi(apiUrl1, postData1)
                ).then(function (response1) {
                    var data1 = response1.data.rows;
                    $.each(data1, function (ind, item) {
                        $(".mrjglx .selectOptions").append($('<li><div>' + item.jglx + '</div><span>' + item.jiagongfei + '</span><span>' + parseFloat((parseFloat(item.sunhaolv) * 100).toPrecision(12)) + '%</span></li >'));
                        // 选择选项写入input
                        $('.mrjglx .selectOptions li').on('click', function () {
                            if (($(this).find("div")[0].innerHTML) == item.jglx) {
                                _this0.jglxCs = item.sortId;
                                _this0.jglxId = item.rowid;
                            }
                            $(".mrjglx .searchInput").val(htmlEscape($(this).find("div")[0].innerHTML))
                            if (htmlEscape($(this).find("div")[0].innerHTML) == item.jglx) {
                                $(".mrjgf input").val(item.jiagongfei)//默认加工费
                                $(".mrshl input").val(parseFloat((parseFloat(item.sunhaolv) * 100).toPrecision(12)) + "%")//默认损耗率
                                // 判断是否需要上浆
                                $('.sfVysj input').each(function () {
                                    $(this).prop('checked', false)
                                })
                                if (item.xysj == 1) {
                                    $(".sfVysj .shi").prop('checked', true);
                                    mrjllxDetails(JSON.parse(item.jlzl)[0].sid)// 显示浆料种类
                                    $(".jllx-tr").removeClass("active2")//显示浆料类型
                                    $(".ac_jllx").removeClass("active")
                                } else {
                                    $(".sfVysj .fou").prop('checked', true);
                                    $(".bottom .tr-td .Ujljg").text("");
                                    $(".bottom .tr-td .Ujlkz").text("");
                                    $(".jllx-tr").addClass("active2")//隐藏浆料类型
                                    $(".ac_jllx").addClass("active");
                                    $(".jllx-tr input").val("");
                                    qingkongFun();
                                }
                                productList3(1, item)
                            }
                            //可以核价
                            if ($(".cpmcBox input").val() != "") {
                                $(".pricingBox").addClass("avtive");
                            }
                        })
                    })
                    var total = response1.data.total;
                    var maxPage = Math.round(total / pageSize) + 1;
                    if (pageIndex < maxPage) {
                        productList1(pageIndex + 1);
                    }
                }).fail(function (error) {
                    console.error('Error:', error);
                });
            }
            // 网布规格列表API
            function productList3(index, res) {
                if (res != undefined) {
                    // Define the URLs of the APIs
                    var apiUrl1 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRows'; //产品列表API
                    var apiUrl2 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRowsTotalNum';//产品列表总行数
                    var pageSize = 100;
                    var pageIndex = index;

                    // Function to make a POST request to an API
                    function callApi(url, data) {
                        return $.ajax({
                            url: url,
                            method: 'POST',
                            dataType: 'json',
                            data: JSON.stringify(data),
                            contentType: 'application/json'
                        });
                    }
                    // Data for each API call
                    var postData1 = {
                        appKey: "de090149861618aa",
                        sign: "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                        worksheetId: "64bb7a8d29545d478c720c24",
                        pageSize: pageSize,
                        pageIndex: pageIndex,
                        sortId: "hjpc"
                    };
                    $.when(
                        callApi(apiUrl1, postData1)
                    ).then(function (response1) {
                        var data1 = response1.data.rows;
                        $(".mrwbgg .selectOptions li").remove();
                        $.each(data1, function (ind, item) {
                            if (res.jglx != "热贴篷布") {
                                if (res.wblx == "机织") {
                                    if (item.bianma.charAt(0) == "W" || item.bianma.charAt(0) == "w") {
                                        itemFun(item);
                                    }
                                }
                                if (res.wblx == "经编") {
                                    if (item.bianma.charAt(0) == "K" || item.bianma.charAt(0) == "k") {
                                        itemFun(item);
                                    }
                                }
                            } else {
                                if (item.bianma.charAt(0) == "W" || item.bianma.charAt(0) == "w" || item.bianma.charAt(0) == "K" || item.bianma.charAt(0) == "k") {
                                    itemFun(item);
                                }
                            }
                            function itemFun(val) {
                                // console.log(val);
                                $(".mrwbgg .selectOptions").append($('<li>' + val.clmc + '</li >'));
                                // 选择选项写入input
                                $('.mrwbgg .selectOptions li').on('click', function () {
                                    if (htmlEscape(this.innerHTML) == val.clmc) {
                                        _this0.wbCs = val.hjpc;
                                        _this0.wbId = val.rowid;
                                    }
                                    $(".active .searchInput").val(htmlEscape(this.innerHTML))
                                    if (htmlEscape(this.innerHTML) == val.clmc) {
                                        $(".wbgg-tr .jgdw input").val(val.dwjg)//价格单位
                                        $(".wbgg-tr .jgdw span").text(val.danwei)//默认单位
                                        $(".wbgg-tr .kz input").val(val.kezhong)//克重
                                        $(".wbgg-tr .clms span").text(val.clms)//材料描述
                                        $(".wbgg-tr .gxrq span").text(val.updatedate)//更新日期
                                    }
                                    //可以核价
                                    if ($(".cpmcBox input").val() != "") {
                                        $(".pricingBox").addClass("avtive");
                                    }
                                })
                            }
                        })
                        var total = response1.data.total;
                        var maxPage = Math.round(total / pageSize);
                        if (pageIndex < maxPage) {
                            productList1(pageIndex + 1);
                        }
                    }).fail(function (error) {
                        console.error('Error:', error);
                    });
                }
            }
            // PVC膜列表API
            function productList4(index) {
                // Define the URLs of the APIs
                var apiUrl1 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRows'; //产品列表API
                var apiUrl2 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRowsTotalNum';//产品列表总行数
                var pageSize = 50;
                var pageIndex = index;

                // Function to make a POST request to an API
                function callApi(url, data) {
                    return $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json',
                        data: JSON.stringify(data),
                        contentType: 'application/json'
                    });
                }
                // Data for each API call
                var postData1 = {
                    appKey: "de090149861618aa",
                    sign: "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                    worksheetId: "64b3e62a346aa010db8013a0",
                    pageSize: pageSize,
                    pageIndex: pageIndex,
                    sortId: "pxqz",
                    isAsc: true
                };
                $.when(
                    callApi(apiUrl1, postData1)
                ).then(function (response1) {
                    var data1 = response1.data.rows;
                    $.each(data1, function (ind, item) {
                        $(".mrPVCm .selectOptions").append($('<li>' + item.clmc + '</li >'));
                        // 选择选项写入input
                        $('.mrPVCm .selectOptions li').on('click', function () {
                            $(".shanchu").each(function () {
                                delFjtxFun(this)//清空附件特性
                            })
                            if (htmlEscape(this.innerHTML) == item.clmc) {
                                _this0.mCs = item.pxqz;
                                _this0.mId = item.rowid;
                            }
                            $(".active .searchInput").val(htmlEscape(this.innerHTML))
                            if (htmlEscape(this.innerHTML) == item.clmc) {
                                $(".pvcm-tr .jgdw input").val(item.jiage)//价格单位
                                $(".pvcm-tr .jgdw span").text(item.danwei)//默认单位
                                $(".pvcm-tr .kz input").val(item.kezhong)//克重
                                $(".pvcm-tr .clms span").text(item.clms)//材料描述
                                $(".pvcm-tr .gxrq span").text(item.updatedate)//更新日期
                            }

                            //可以核价
                            if ($(".cpmcBox input").val() != "") {
                                $(".pricingBox").addClass("avtive");
                            }
                        })
                    })
                    var total = response1.data.total;
                    var maxPage = Math.round(total / pageSize) + 1;
                    if (pageIndex < maxPage) {
                        productList1(pageIndex + 1);
                    }
                }).fail(function (error) {
                    console.error('Error:', error);
                });
            }
            // 附加特性
            function productList6(index) {
                // Define the URLs of the APIs
                var apiUrl1 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRows'; //产品列表API
                var apiUrl2 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRowsTotalNum';//产品列表总行数
                var pageSize = 50;
                var pageIndex = index;

                // Function to make a POST request to an API
                function callApi(url, data) {
                    return $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json',
                        data: JSON.stringify(data),
                        contentType: 'application/json'
                    });
                }
                // Data for each API call
                var postData1 = {
                    appKey: "de090149861618aa",
                    sign: "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                    worksheetId: "64c0d4ecbb5a76c253907792",
                    pageSize: pageSize,
                    pageIndex: pageIndex,
                    sortId: "paxvId"
                };
                $.when(
                    callApi(apiUrl1, postData1)
                ).then(function (response1) {
                    var data1 = response1.data.rows;
                    $.each(data1, function (ind, item) {
                        productList6Data.push(item)
                    })

                    var total = response1.data.total;
                    var maxPage = Math.round(total / pageSize) + 1;
                    if (pageIndex < maxPage) {
                        productList1(pageIndex + 1);
                    } else {
                        localStorage.setItem('cachedData', JSON.stringify(productList6Data));
                    }
                }).fail(function (error) {
                    console.error('Error:', error);
                });
            }





            // 浆料类型列表API
            function productList5(index) {
                // Define the URLs of the APIs
                var apiUrl1 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRows'; //产品列表API
                var apiUrl2 = 'https://api.mingdao.com/v2/open/worksheet/getFilterRowsTotalNum';//产品列表总行数
                var pageSize = 50;
                var pageIndex = index;

                // Function to make a POST request to an API
                function callApi(url, data) {
                    return $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json',
                        data: JSON.stringify(data),
                        contentType: 'application/json'
                    });
                }
                // Data for each API call
                var postData1 = {
                    appKey: "de090149861618aa",
                    sign: "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                    worksheetId: "64bb7678d470ff76b32ef616",
                    pageSize: pageSize,
                    pageIndex: pageIndex
                };
                $.when(
                    callApi(apiUrl1, postData1)
                ).then(function (response1) {
                    var data1 = response1.data.rows;
                    $.each(data1, function (ind, item) {
                        $(".mrjllx .selectOptions").append($('<li>' + item.clmc + '</li >'));
                        // 选择选项写入input
                        $('.mrjllx .selectOptions li').on('click', function () {
                            $(".active.mrjllx .searchInput").val(htmlEscape(this.innerHTML))
                            if (htmlEscape(this.innerHTML) == item.clmc) {
                                $(".jllx-tr .jgdw input").val(item.dwjg)//价格单位
                                $(".jllx-tr .jgdw span").text(item.danwei)//默认单位
                                $(".jllx-tr .kz input").val(item.kezhong)//克重
                                $(".jllx-tr .clms span").text(item.clms)//材料描述
                                $(".jllx-tr .gxrq span").text(item.updatedate)//更新日期
                            }
                            //可以核价
                            if ($(".cpmcBox input").val() != "") {
                                $(".pricingBox").addClass("avtive");
                            }
                        })
                    })
                    var total = response1.data.total;
                    var maxPage = Math.round(total / pageSize) + 1;
                    if (pageIndex < maxPage) {
                        productList1(pageIndex + 1);
                    }
                }).fail(function (error) {
                    console.error('Error:', error);
                });
            }

            // 加工类型详情API
            function jglxDetails(rowId) {
                var apiUrl1 = 'https://api.mingdao.com/v2/open/worksheet/getRowByIdPost';
                function callApi(url, data) {
                    return $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json',
                        data: JSON.stringify(data),
                        contentType: 'application/json'
                    });
                }
                var postData1 = {
                    appKey: "de090149861618aa",
                    sign: "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                    worksheetId: "64afa8ee6d548a8f59c4c73a",
                    rowId: rowId

                };
                $.when(
                    callApi(apiUrl1, postData1)
                ).then(function (response1) {
                    var data1 = response1.data;
                    $(".mrjglx input").val(data1.jglx)//默认加工类型
                    $(".mrjgf input").val(data1.jiagongfei)//默认加工费
                    $(".mrshl input").val(parseFloat((data1.sunhaolv * 100).toPrecision(12)) + "%")//默认损耗率
                    ListeningDefaults(data1.jiagongfei, $(".mrjgf input").attr("class"));//加工费赋值
                    ListeningDefaults(data1.sunhaolv * 100 + "%", $(".mrshl input").attr("class"));//损耗率赋值
                    // 判断是否需要上浆
                    $('.sfVysj input').each(function () {
                        $(this).prop('checked', false)
                    })
                    if (data1.xysj == 1) {
                        $(".sfVysj .shi").prop('checked', true)
                        mrjllxDetails(JSON.parse(data1.jlzl)[0].sid)// 显示浆料种类
                        $(".jllx-tr").removeClass("active2")//显示浆料类型
                        $(".ac_jllx").removeClass("active")
                    } else {
                        $(".sfVysj .fou").prop('checked', true)
                        $(".bottom .tr-td .Ujljg").text("");
                        $(".bottom .tr-td .Ujlkz").text("");
                        $(".jllx-tr").addClass("active2");//隐藏浆料类型
                        $(".ac_jllx").addClass("active");
                        $(".jllx-tr input").val("");
                        qingkongFun();
                    }
                    //判断网布类型
                    productList3(1, data1)
                }).fail(function (error) {
                    console.error('Error:', error);
                });
            }
            // 网布规格详情API
            function wbggDetails(rowId) {
                var apiUrl1 = 'https://api.mingdao.com/v2/open/worksheet/getRowByIdPost';
                function callApi(url, data) {
                    return $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json',
                        data: JSON.stringify(data),
                        contentType: 'application/json'
                    });
                }
                var postData1 = {
                    appKey: "de090149861618aa",
                    sign: "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                    worksheetId: "64bb7a8d29545d478c720c24",
                    rowId: rowId

                };
                $.when(
                    callApi(apiUrl1, postData1)
                ).then(function (response1) {
                    var data1 = response1.data;
                    $(".wbgg-tr .clmc input").val(data1.clmc)//默认材料名称
                    $(".wbgg-tr .jgdw input").val(data1.dwjg)//默认价格
                    $(".wbgg-tr .jgdw span").text(data1.danwei)//默认单位
                    $(".wbgg-tr .kz input").val(data1.kezhong)//默认克重
                    $(".wbgg-tr .clms span").text(data1.clms)//默认材料描述
                    $(".wbgg-tr .gxrq span").text(data1.updatedate)//更新日期
                    ListeningDefaults(data1.dwjg, $(".wbgg-tr .jgdw input").attr("class"));//价格赋值
                    ListeningDefaults(data1.kezhong, $(".wbgg-tr .kz input").attr("class"));
                }).fail(function (error) {
                    console.error('Error:', error);
                });
            }
            // PVC膜详情API
            function mrpvcmDetails(rowId) {
                var apiUrl1 = 'https://api.mingdao.com/v2/open/worksheet/getRowByIdPost';
                function callApi(url, data) {
                    return $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json',
                        data: JSON.stringify(data),
                        contentType: 'application/json'
                    });
                }
                var postData1 = {
                    appKey: "de090149861618aa",
                    sign: "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                    worksheetId: "64b3e62a346aa010db8013a0",
                    rowId: rowId

                };
                $.when(
                    callApi(apiUrl1, postData1)
                ).then(function (response1) {
                    var data1 = response1.data;
                    $(".pvcm-tr .clmc input").val(data1.clmc)//默认材料名称
                    $(".pvcm-tr .jgdw input").val(data1.jiage)//默认价格
                    $(".pvcm-tr .jgdw span").text(data1.danwei)//默认单位
                    $(".pvcm-tr .kz input").val(data1.kezhong)//默认克重
                    $(".pvcm-tr .clms span").text(data1.clms)//默认材料描述
                    $(".pvcm-tr .gxrq span").text(data1.updatedate)//更新日期
                    ListeningDefaults(data1.jiage, $(".pvcm-tr .jgdw input").attr("class"));
                }).fail(function (error) {
                    console.error('Error:', error);
                });
            }

            // 浆料类型API
            function mrjllxDetails(rowId) {
                var apiUrl1 = 'https://api.mingdao.com/v2/open/worksheet/getRowByIdPost';
                function callApi(url, data) {
                    return $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json',
                        data: JSON.stringify(data),
                        contentType: 'application/json'
                    });
                }
                var postData1 = {
                    appKey: "de090149861618aa",
                    sign: "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                    worksheetId: "64bb7678d470ff76b32ef616",
                    rowId: rowId

                };
                $.when(
                    callApi(apiUrl1, postData1)
                ).then(function (response1) {
                    var data1 = response1.data;
                    $(".jllx-tr .clmc input").val(data1.clmc)//默认材料名称
                    $(".jllx-tr .jgdw input").val(data1.dwjg)//默认价格
                    $(".jllx-tr .jgdw span").text(data1.danwei)//默认单位
                    $(".jllx-tr .kz input").val(data1.kezhong)//默认克重
                    $(".jllx-tr .clms span").text(data1.clms)//默认材料描述
                    $(".jllx-tr .gxrq span").text(data1.updatedate)//更新日期
                }).fail(function (error) {
                    console.error('Error:', error);
                });
            }

            var thiss = this;
            $("input").each(function (index) {
                $(this).attr("id", "input" + index)
            })
            $(".pricingBox a").on("click", function () {
                if ($(".mrjglx input").val() == "") {
                    $(".mrjglx").addClass("active9");
                } else {
                    $(".mrjglx").removeClass("active9");
                }
                if ($(".mrwbgg input").val() == "") {
                    $(".mrwbgg").addClass("active9");
                } else {
                    $(".mrwbgg").removeClass("active9");
                }
                if ($(".mrPVCm input").val() == "") {
                    $(".mrPVCm").addClass("active9");
                } else {
                    $(".mrPVCm").removeClass("active9");
                }
                if ($(".mrkz input").val() == "") {
                    $(".mrkz").addClass("active9");
                } else {
                    $(".mrkz").removeClass("active9");
                }
                if ($(".mrjglx input").val() != "" && $(".mrwbgg input").val() != "" && $(".mrPVCm input").val() != "" && $(".mrkz input").val() != "") {
                    costingAccount();

                    $(".pricingBox").removeClass("avtive");
                    PricingFrequency(parseInt(_this0.wbCs), _this0.wbId, "网布规格")//核价次数
                    PricingFrequency(parseInt(_this0.jglxCs), _this0.jglxId, "加工工艺(类型)")//核价次数
                    PricingFrequency(parseInt(_this0.mCs), _this0.mId, "PVC膜")//核价次数
                    PricingFrequency(parseInt(_this0.fjtxCs), _this0.fjtxId, "附加特性")//核价次数
                    //核价历史纪录
                    var pvcfjtxS = "";
                    var pvcfjtxAll = 0;
                    $(".pvcfjtx-tr0").each(function () {
                        if (pvcfjtxS == "") {
                            pvcfjtxS += $(this).find(".pvcfjtx input").val();
                        } else {
                            pvcfjtxS = pvcfjtxS + "," + $(this).find(".pvcfjtx input").val();
                        }
                        pvcfjtxAll += parseFloat($(this).find(".jgdw .fjtx").val());
                    })
                    var PricingData = {
                        'productName': $(".cpmcBox input").val(),
                        'price': $(".pricingBox span b").text(),
                        'processingType': $(".mrjglx input").val(),
                        'meshSpecifications': $(".mrwbgg input").val(),
                        'apvcFilm': $(".mrPVCm input").val(),
                        'slurryType': $(".mrjllx input").val(),
                        'attachmentFeatures': pvcfjtxS,
                        'productWeight': $("span.Ucpkz").text(),
                        'membraneValence': $("span.Upvcmjg").text(),
                        'fabricPrice': $("span.Uwbjg").text(),
                        'lothWeight': $("span.Uwbkz").text(),
                        'processingFees': $("span.Ujgf").text(),
                        'lossRate': parseFloat($("span.Ushl").text()),
                        'slurryPrice': $("span.Ujljg").text(),
                        'weightSlurry': $("span.Ujlkz").text(),
                        'attachmentFeaturesAll': pvcfjtxAll / 1000000
                    }
                    // WriteRecord(PricingData);
                    // console.log(PricingData);
                }


            });
        });

        /*传入html字符串源码即可*/
        function htmlEscape(text) {
            return text.replace(/&gt;/g, function (match, pos, originalText) {
                switch (match) {
                    case "&gt;": return ">";
                }
            });
            return text.replace(/&lt;/g, function (match, pos, originalText) {
                switch (match) {
                    case "&lt;": return "<";
                }
            });
        }

        // 监听输入框有改变，核价按钮高亮可点
        originalValue();
        function originalValue(res) {
            setTimeout(function () {
                if (res == undefined) {
                    var initialValues = {};
                    $("input:not(.cpmcBox input)").each(function () {
                        var id = $(this).attr("id");
                        initialValues[id] = $(this).val();
                    });
                    $("input:not(.sfVysj input)").on("input", function () {
                        if ($(".cpmcBox input").val() != "") {
                            var id = $(this).attr("id");
                            var currentValue = $(this).val();
                            if (currentValue !== initialValues[id]) {
                                $(".pricingBox").addClass("avtive");
                            } else {
                                // $(".pricingBox").removeClass("avtive");
                            }
                        }
                    });
                } else {
                }
            }, 1000);


        }
        originalValue1();
        function originalValue1(res) {
            // console.log($(".pvcfjtx-tr input.fjtx"));
            $(".pvcfjtx-tr input.fjtx").on("input", function () {
            });
        }
        // 核算价格
        function costingAccount() {
            //开始核价
            var Ucpkz = 0, Ujgf = 0, Ushl = 0, Uwbjg = 0, Uwbkz = 0, Upvcmjg = 0, Ujljg = 0, Ujlkz = 0, fjtxAll = 0;
            $(".unisign .WriteData").each(function (ind, item) {
                // console.log(item);
                $(".bottom td span").each(function (ind1, item1) {
                    if ($(item1).attr('class') == "Ucpkz" && $(item).find(".mrkz input").hasClass("Ucpkz")) {
                        $(".bottom .Ucpkz").text($(item).find(".mrkz input").val());
                        Ucpkz = $(".bottom .Ucpkz").text();//产品克重
                    }
                    if ($(item1).attr('class') == "Ujgf" && $(item).find(".mrjgf input").hasClass("Ujgf")) {
                        $(".bottom .Ujgf").text($(item).find(".mrjgf input").val());
                        if ($(".bottom .Ujgf").text() == "") {
                            Ujgf = 0;
                        } else {
                            Ujgf = parseFloat($(".bottom .Ujgf").text());//加工费
                        }

                    }
                    if ($(item1).attr('class') == "Ushl" && $(item).find(".mrshl input").hasClass("Ushl")) {
                        $(".bottom .Ushl").text($(item).find(".mrshl input").val());
                        if ($(".bottom .Ushl").text() == "") {
                            Ushl = 0;
                        } else {
                            Ushl = parseFloat($(".bottom .Ushl").text()) / 100 + 1;//损耗率
                        }

                    }
                    if ($(item1).attr('class') == "Uwbjg" && $(item).find("input.Uwbjg").hasClass("Uwbjg")) {
                        $(".bottom .Uwbjg").text($(item).find("input.Uwbjg").val());
                        if ($(".bottom .Uwbjg").text() == "") {
                            Uwbjg = 0;
                        } else {
                            Uwbjg = parseFloat($(".bottom .Uwbjg").text());//网布价格
                        }

                    }
                    if ($(item1).attr('class') == "Uwbkz" && $(item).find("input.Uwbkz").hasClass("Uwbkz")) {
                        $(".bottom .Uwbkz").text($(item).find("input.Uwbkz").val());
                        if ($(".bottom .Uwbkz").text() == "") {
                            Uwbkz = 0;
                        } else {
                            Uwbkz = parseFloat($(".bottom .Uwbkz").text());//网布克重
                        }

                    }
                    if ($(item1).attr('class') == "Upvcmjg" && $(item).find("input.Upvcmjg").hasClass("Upvcmjg")) {
                        if ($(item).find("input.Upvcmjg").val() == "") {
                            Upvcmjg = 0;
                            $(".bottom .Upvcmjg").text(0);
                        } else {
                            $(".bottom .Upvcmjg").text(parseFloat($(item).find("input.Upvcmjg").val()));
                            Upvcmjg = parseFloat($(".bottom .Upvcmjg").text()) / 1000000;//膜价
                        }
                    }
                    if ($(item1).attr('class') == "Ujljg" && $(item).find("input.Ujljg").hasClass("Ujljg") && $(".sfVysj .shi").prop('checked') == true) {
                        if ($(item).find(".jgdw span").text() == "元 / 吨") {
                            $(".bottom .Ujljg").text(parseFloat($(item).find("input.Ujljg").val()));
                        } else {
                            if ($(item).find(".jgdw span").text() !== "") {
                                $(".bottom .Ujljg").text($(item).find("input.Ujljg").val() + "每平方");
                            }
                        }
                        if ($(".bottom .Ujljg").text() == "") {
                            Ujljg = 0;
                        } else {
                            if ($(".bottom .Ujljg").text().indexOf("每平方") !== -1) {
                            } else {
                                Ujljg = parseFloat($(".bottom .Ujljg").text()) / 1000000;//浆料价格
                            }
                        }
                    }
                    if ($(item1).attr('class') == "Ujlkz" && $(item).find("input.Ujlkz").hasClass("Ujlkz")) {
                        $(".bottom .Ujlkz").text($(item).find("input.Ujlkz").val());
                        if ($(".bottom .Ujlkz").text() == "") {
                            Ujlkz = 0;
                        } else {
                            Ujlkz = parseFloat($(".bottom .Ujlkz").text());//浆料克重
                        }

                    }
                })
            })
            var fjtxAllPf = 0;
            $(".bottom .tr-td").find("span.fjtx").each(function (ind, item) {
                if ($(this).text().indexOf("每平方") !== -1) {
                    fjtxAllPf += parseFloat($(item).text())//附加特性总价-平方计算
                } else {
                    fjtxAll += parseFloat($(item).text()) / 1000000;//附加特性总价-吨位计算
                }
            })
            var TotalMembrane = (Ucpkz - Uwbkz - Ujlkz) * (Upvcmjg + fjtxAll);//膜成本
            var TotalSlurry = Ujljg * Ujlkz;//浆料成本
            var totalCost = (TotalMembrane + TotalSlurry + Uwbjg) * Ushl + Ujgf + fjtxAllPf;//(膜成本+浆料成本+网布价格)*损耗率+加工费
            $(".pricingBox b").text(totalCost.toFixed(4))
            var a = {
                "产品克重": Ucpkz,
                "网布克重": Uwbkz,
                "浆料克重": Ujlkz,
                "膜价": Upvcmjg,
                "附加特性": fjtxAll,
                "膜总价": TotalMembrane,
                "浆料价格": Ujljg,
                "浆料克重": Ujlkz,
                "浆料成本": TotalSlurry,
                "网布价格": Uwbjg,
                "损耗率": Ushl,
                "加工费": Ujgf,
                "附加特性（平方）": fjtxAllPf,
                "成本": totalCost
            }
            // console.log(a);

        }

        // 写入核价历史纪录
        function WriteRecord(record) {
            //获取当前日期
            const currentDate = new Date();
            // 从cookie中获取用户信息
            function getUserInfoFromCookie() {
                const cookies = document.cookie.split(';');
                let userInfo = null;
                for (const cookie of cookies) {
                    const [name, value] = cookie.trim().split('=');
                    if (name === 'userInfo') {
                        userInfo = JSON.parse(value);
                        break;
                    }
                }
                return userInfo;
            }
            const userInfo = getUserInfoFromCookie();
            // 数据准备
            var requestData = {
                "appKey": "de090149861618aa",
                "sign": "OTAxMWFkN2EyMmUzMGE5NjY2ODAzNDY1YzYxNjY3ZDdmMmRhNGJlZGM4NzRiMzAyMTUwMmFkMjAyOGJmYWZlYw==",
                "worksheetId": "64dccffd504e677c03e2d901",
                "controls": [
                    {
                        "controlId": "cpbm",
                        "value": record.productName
                    },
                    {
                        "controlId": "chengbenjia",
                        "value": record.price
                    },
                    {
                        "controlId": "fuzeren",
                        "value": userInfo.username
                    },
                    {
                        "controlId": "kezhong",
                        "value": record.productWeight
                    },
                    {
                        "controlId": "mrwbgg",
                        "value": record.meshSpecifications
                    },
                    {
                        "controlId": "mrpvcm",
                        "value": record.apvcFilm
                    },
                    {
                        "controlId": "mrjggy_lx_",
                        "value": record.processingType
                    },
                    {
                        "controlId": "fjtx",
                        "value": record.attachmentFeatures
                    },
                    {
                        "controlId": "jllx",
                        "value": record.slurryType
                    },
                    {
                        "controlId": "mojia",
                        "value": record.membraneValence
                    },
                    {
                        "controlId": "wbjg",
                        "value": record.fabricPrice
                    },
                    {
                        "controlId": "wbkz",
                        "value": record.lothWeight
                    },
                    {
                        "controlId": "jiagongfei",
                        "value": record.processingFees
                    },
                    {
                        "controlId": "sunhaolv",
                        "value": record.lossRate
                    },
                    {
                        "controlId": "jljg",
                        "value": record.slurryPrice
                    },
                    {
                        "controlId": "jlkz",
                        "value": record.weightSlurry
                    },
                    {
                        "controlId": "fjtxjehz",
                        "value": record.attachmentFeaturesAll
                    },
                    {
                        "controlId": "hjrq",
                        "value": currentDate
                    },
                    {
                        "controlId": "ownerid",
                        "value": ""
                    }
                ],
                "triggerWorkflow": true
            };
            // 发起POST请求
            $.ajax({
                url: "https://api.mingdao.com/v2/open/worksheet/addRow",
                type: "POST",
                data: JSON.stringify(requestData),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response) {
                    // console.log(response);
                },
                error: function (xhr, status, error) {
                    // 处理错误
                    $("#response").text("错误：" + error);
                }
            });
        }

        this.ruleFun();//操作规则
        // this.toggleReadOnly(0);
    },
    methods: {
        ruleFun() {
            //加工类型为空时禁止操作
            if ($(".mrjglx input").val() == "") {
                $(".middle").addClass("active9");
                $(".mrjgf").addClass("active9");
                $(".mrshl").addClass("active9");
            }
            $(document).on(('click'), function (e) {
                if ($(".mrjglx input").val() == "") {
                    if (e.target.closest(".top") == null) {
                        $(".middle").addClass("active9");
                        $(".mrjgf").addClass("active9");
                        $(".mrshl").addClass("active9");
                        $(".mrjglx").addClass("active9");
                    }
                } else {
                    $(".middle").removeClass("active9");
                    $(".mrjgf").removeClass("active9");
                    $(".mrshl").removeClass("active9");
                    $(".mrjglx").removeClass("active9");
                }
                if ($(".mrkz input").val() != "") {
                    $(".mrkz").removeClass("active9");
                }
                if ($(".mrwbgg input").val() != "") {
                    $(".mrwbgg").removeClass("active9");
                }
                if ($(".mrPVCm  input").val() != "") {
                    $(".mrPVCm ").removeClass("active9");
                }

            });
        },
        //获得焦点时清楚原本的内容
        handleFocus: function (e) {
            e.target.value = "";
        }
    }

};
</script>